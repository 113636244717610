import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function SlideAnimate({ children, direction, inView, entry }) {
  let initialStyle;
  let animateStyle;
  switch (direction) {
    case "top":
      initialStyle = { y: 15, opacity: 0 };
      animateStyle = { y: 0, opacity: 1 };
      break;
    case "bottom":
      initialStyle = { y: -15, opacity: 0 };
      animateStyle = { y: 0, opacity: 1 };
      break;
    case "start":
      initialStyle = { x: 15, opacity: 0 };
      animateStyle = { x: 0, opacity: 1 };
      break;
    case "end":
      initialStyle = { x: -15, opacity: 0 };
      animateStyle = { x: 0, opacity: 1 };
    case "top-no-opacity":
      initialStyle = { y: 15 };
      animateStyle = { y: 0 };
  }

  const childVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <AnimatePresence>
      {inView && (
        <motion.div
          transition={{
            ease: [0.16, 1, 0.3, 1],
          }}
          initial={initialStyle}
          animate={animateStyle}
          exit={initialStyle}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
