import { Container, Button } from "@mui/material";
import * as React from "react";
import Divider from "@mui/material/Divider";
import { useAtom } from "jotai";

import ToggleButton from "@mui/material/ToggleButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// import MapRoundedIcon from "@mui/icons-material/MapRounded";
// import ListIcon from "@mui/icons-material/List";
import ListIcon from "../components/icons/list-icon";
import MapIcon from "./icons/map-icon";
import Link from "./nextjs-link";
import { NextLinkComposed } from "./nextjs-link";
// import MegaphoneIconFooter from "./icons/megaphone-footer";
import MegaphoneIcon from "./icons/megaphone-color";
import { useRouter } from "next/router";
import useMediaQuery from "@mui/material/useMediaQuery";
import { use100vh, measureHeight } from "react-div-100vh";
import {
  mapListToggleAtom,
  modalLoginAtom,
  watchlistToggleAtom,
  navSelectionAtom,
  footerHeightAtom,
  mobileFooterHeightAtom,
} from "../utils/store";
import useUserData from "../utils/hooks/use-user-data";
import useSubscriptionStatus from "../utils/stripe/useSubscriptionStatus";

export default function NewFooter({ inView }) {
  const heightMeasured = measureHeight();
  const [footerHeight, setFooterHeight] = useAtom(footerHeightAtom);
  const [mobileFooterHeight, seMobileFooterHeight] = useAtom(
    mobileFooterHeightAtom
  );
  const { user, authIsReady } = useUserData();
  const [mapListToggle, setMapListToggle] = useAtom(mapListToggleAtom);
  const [modalLogin, setModalLogin] = useAtom(modalLoginAtom);
  const [watchListToggle, setWatchListToggle] = useAtom(watchlistToggleAtom);

  const [selection, setSelection] = useAtom(navSelectionAtom);
  const subStatus = useSubscriptionStatus(user);

  const smDown = useMediaQuery("(max-width:600px)");

  const router = useRouter();

  const adjustHeight = (heightInput) => {
    if (smDown) {
      if (heightMeasured > 550) {
        return heightInput;
      }
      return 0;
    }
  };

  const checkPathnameValue = () => {
    if (!router.isReady) return;
    const path = router.pathname;
    switch (path) {
      case "/alerts":
      case "/account":
      case "/pricing":
      case "/search":
        break;
      default:
        return false;
    }
    return path;
  };

  const renderCorrectFooter = () => {
    if (!user) {
      return <Footer />;
    } else {
      switch (router.pathname) {
        case "/alerts":
        case "/account":
        case "/pricing":
        case "/search":
        case "/pricing-two":
          return <MobileFooter />;
        case "/login":
          return <Footer />;
        default:
          return <Footer />;
      }
    }
  };

  const linkPath = () => {
    if (user && authIsReady) {
      // if (subStatus.status) {
      //   return "/alerts";
      // }
      return "/alerts";
    }
    return "/login";
  };

  const MobileFooter = () => {
    const mapColorRender = () => {};

    return (
      <ToggleButtonGroup
        value={checkPathnameValue()}
        exclusive
        sx={{
          backgroundColor: "white",
          width: "100%",
          borderRadius: 0,
          padding: 0,
          boxShadow: !inView
            ? "0px -1px 2px rgb(0 0 0 / 8%), 0px -4px 12px rgb(0 0 0 / 5%) !important"
            : "none",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
            height: adjustHeight(mobileFooterHeight),
            color: "black",
            zIndex: 10,
            background: "white",
          }}
          divider={<Divider orientation="vertical" flexItem variant="middle" />}
        >
          <ToggleButton
            component={NextLinkComposed}
            to={{
              pathname: linkPath(),
            }}
            value="/alerts"
            aria-label="alerts"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              border: "none",
              borderRadius: "0px",
              textTransform: "capitalize",
              height: "100%",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                padding: "5px 20px",
                minWidth: "85px",
                minHeight: "60px",
                border:
                  router.pathname === "/alerts"
                    ? "1px solid black"
                    : "1px solid transparent",
              }}
            >
              <MegaphoneIcon fontSize="small" />
              <Typography
                sx={{
                  fontSize: 10,
                  fontWeight: 600,
                  mt: "5px",
                  color: "black",
                }}
              >
                Alert List
              </Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton
            component={NextLinkComposed}
            to={{
              pathname: "/search",
            }}
            value="/search"
            aria-label="list-map"
            sx={{
              border: "none",
              borderRadius: "0px",
              textTransform: "capitalize",
              height: "100%",
              paddingTop: 0,
              paddingBottom: 0,
            }}
            onClick={() => {
              if (mapListToggle) return;
              setMapListToggle(!mapListToggle);
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                minWidth: "85px",
                minHeight: "60px",
                padding: "5px 20px",
                border:
                  router.pathname !== "/search"
                    ? "1px solid transparent"
                    : router.pathname === "/search" && mapListToggle
                    ? "1px solid black"
                    : "1px solid transparent",
              }}
            >
              <ListIcon />
              <Typography
                sx={{
                  fontSize: 10,
                  fontWeight: 600,
                  mt: "5px",
                  color: "black",
                }}
              >
                List
              </Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton
            component={NextLinkComposed}
            to={{
              pathname: "/search",
            }}
            value="/search"
            aria-label="list-map"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              border: "none",
              textTransform: "capitalize",
              borderRadius: "0px",
              height: "100%",
            }}
            onClick={() => {
              if (!mapListToggle) return;
              setMapListToggle(!mapListToggle);
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
                minWidth: "85px",
                minHeight: "60px",
                padding: "5px 20px",
                border:
                  router.pathname !== "/search"
                    ? "1px solid transparent"
                    : router.pathname === "/search" && mapListToggle
                    ? "1px solid transparent"
                    : "1px solid black",
              }}
            >
              <MapIcon bottomMargin="0px" />
              <Typography
                sx={{
                  fontSize: 10,
                  fontWeight: 600,
                  mt: "5px",
                  color: "black",
                }}
              >
                Map
              </Typography>
            </Stack>
          </ToggleButton>
        </Stack>
      </ToggleButtonGroup>
    );
  };

  const Footer = () => {
    return (
      <Stack
        sx={{
          width: "100%",
          height: adjustHeight(footerHeight),
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Stack
          direction="row"
          sx={{ alignItems: "center", height: "fit-content" }}
        >
          {!smDown && (
            <>
              <Typography
                sx={{
                  padding: "6px 8px",
                  fontWeight: 500,
                  lineHeight: 1.75,
                  fontSize: "14px",
                }}
              >
                Campwatch 2022
              </Typography>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ backgroundColor: "black" }}
              />
              {/* {router.pathname !== "/affiliates" && (
                <>
                  <Button
                    component={NextLinkComposed}
                    to={{
                      pathname: "/affiliates",
                    }}
                    variant="text"
                    sx={{ textTransform: "capitalize", color: "black" }}
                  >
                    Affiliates
                  </Button>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ backgroundColor: "black" }}
                  />
                </>
              )} */}
            </>
          )}
          <Button
            component={NextLinkComposed}
            to={{
              pathname: "/terms",
            }}
            variant="text"
            sx={{ textTransform: "capitalize", color: "black" }}
          >
            Terms
            <span style={{ textTransform: "lowercase" }}>&nbsp;of&nbsp;</span>
            Service
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ backgroundColor: "black" }}
          />
          <Button
            component={NextLinkComposed}
            to={{
              pathname: "/privacy",
            }}
            variant="text"
            sx={{ textTransform: "capitalize", color: "black" }}
          >
            Privacy Policy
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ backgroundColor: "black" }}
          />
          <a
            style={{
              padding: "6px 8px",
              fontWeight: 500,
              lineHeight: 1.75,
              fontSize: "14px",
              letterSpacing: "0.02857em",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            }}
            href="mailto: jesse@campwatch.co"
          >
            Contact
          </a>
        </Stack>
      </Stack>
    );
  };

  return smDown ? renderCorrectFooter() : <Footer />;
}
