import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import ReactPlayer from "react-player/lazy";
import { storage } from "../utils/firebase";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { SizeMe } from "react-sizeme";
import useMediaQuery from "@mui/material/useMediaQuery";
import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

// Only loads the YouTube player

const PlaceHolderImage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        className="play-button"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          borderRadius: "10px",
          padding: "5px",
        }}
      >
        <PlayArrowRoundedIcon sx={{ color: "white" }} />
        <Typography sx={{ fontWeight: 600, fontSize: "24px", color: "white" }}>
          Watch Demo
        </Typography>
      </Stack>
    </Box>
  );
};

export default function VideoPlayerTest({ phoneHeight }) {
  const smDown = useMediaQuery("(max-width:600px)");
  const [vidUrl, setVidUrl] = useState("");
  const storageRef = storage.ref();
  const videoRef = storageRef.child("phone-demo.mp4");
  videoRef
    .getDownloadURL()
    .then((url) => {
      setVidUrl(url);
    })
    .catch((error) => {
      console.log(error);
    });

  const phoneWidth = phoneHeight * 0.51833;

  return (
    <Box
      sx={{
        position: "relative",
        height: phoneHeight * 0.95,
        width: phoneWidth * 0.87,
        overflow: "hidden",
        borderRadius: "10px",
        marginBottom: "10px",
        marginRight: "2px",
      }}
    >
      <ReactPlayer
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        // light="./squamish-valley.jpg"
        id="myvid"
        url={vidUrl}
        width={phoneWidth * 1.05}
        height={phoneHeight * 1.05}
        playing={true}
        muted={true}
        controls={false}
        // volume={1}
        loop={true}
        // progressInterval={5000}
        pip={false}
        // playIcon={<PlaceHolderImage />}
      />
    </Box>
  );
}
