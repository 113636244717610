import { Stack, Typography, Divider, Button } from "@mui/material";
import React from "react";
import { NextLinkComposed } from "./nextjs-link";

export default function FooterRowAffiliateMobile() {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          padding: "6px 8px",
          fontWeight: 500,
          lineHeight: 1.75,
          fontSize: "14px",
        }}
      >
        Campwatch 2022
      </Typography>
      {/* <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ backgroundColor: "black" }}
      />
      <Button
        component={NextLinkComposed}
        to={{
          pathname: "/affiliates",
        }}
        variant="text"
        sx={{ textTransform: "capitalize", color: "black" }}
      >
        Affiliates
      </Button> */}
    </Stack>
  );
}
