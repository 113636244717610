import React, { useEffect, useRef } from "react";
import useUserData from "../utils/hooks/use-user-data";
import { use100vh } from "react-div-100vh";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Image from "next/image";
import Container from "@mui/material/Container";
import { SizeMe } from "react-sizeme";
import { useRouter } from "next/router";
import Navbar from "../components/navbar";
import ButtonGetStarted from "../components/button-get-started";
import { useAtom } from "jotai";
import {
  mobileFooterHeightAtom,
  footerHeightAtom,
  navBarHeightAtom,
  watchlistToggleAtom,
  mapListToggleAtom,
  navSelectionAtom,
} from "../utils/store";
import NewFooter from "../components/newFooter";
import VideoPlayer from "../components/video-player";
import VideoPlayerTest from "../components/video-player-test";
import { motion } from "framer-motion";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import SlideAnimate from "../utils/SlideAnimate";
import { useInView } from "react-intersection-observer";
import SlideStaggeredChildren from "../utils/SlideStaggeredChildren";
import SlideStaggeredChildrenMobile from "../utils/SlideStaggeredChildrenMobile";
import { Button } from "@mui/material";
import { NextLinkComposed } from "../components/nextjs-link";
import FooterRowAffiliateMobile from "../components/footerRowAffiliateMobile";

export default function Home() {
  const ref = useRef();

  const { user, authIsReady } = useUserData();
  const smDown = useMediaQuery("(max-width:600px)");
  const height = use100vh();
  const router = useRouter();

  const [mobileFooterHeight, setMobileFooterHeight] = useAtom(
    mobileFooterHeightAtom
  );
  const [footerHeight, setFooterHeight] = useAtom(footerHeightAtom);
  const [navBarHeight, setNavBarHeight] = useAtom(navBarHeightAtom);

  const imgHeight = (height) => {
    if (smDown) {
      return 188.5;
    }
    return height;
  };

  const { ref: ref1, inView: inView1 } = useInView({
    rootMargin: "-100px 0px",
  });
  const { ref: ref2, inView: inView2 } = useInView({
    rootMargin: "-100px 0px",
  });
  const { ref: ref3, inView: inView3 } = useInView({
    rootMargin: "-250px 0px",
  });
  const { ref: ref4, inView: inView4 } = useInView({
    rootMargin: "0px 0px",
  });
  const { ref: ref5, inView: inView5 } = useInView({
    rootMargin: "-100px 0px",
  });
  const { ref: ref6, inView: inView6 } = useInView({
    rootMargin: "-100px 0px",
  });

  useEffect(() => {
    if (user) router.push("/search");
  }, []);

  useEffect(() => {
    if (user) router.push("/search");
  }, [user]);

  // useEffect(() => {
  //   $crisp.push(["do", "chat:hide"]);
  // }, [$crisp]);

  const linkList = [
    // { href: "/ca/ventura-beach-camping", title: "Ventura Beach camping" },
    {
      href: "/ca/santa-barbara-beach-camping",
      title: "Santa Barbara beach camping",
    },
    {
      href: "/ca/pismo-beach-camping",
      title: "Pismo Beach camping",
    },
    {
      href: "/ca/mammoth-lakes-camping",
      title: "Mammoth Lakes Camping",
    },
  ];

  const linkList2 = [
    {
      href: "/campground/santa-barbara-parks-cachuma-lake",
      title: "Cachuma Lake",
    },
    {
      href: "/campground/montana-de-oro-state-park-islay-creek-campground",
      title: "Islay Creek Campground",
    },
    {
      href: "/campground/leo-carrillo-state-park-canyon-campground-(sites-1-24-78-133)",
      title: "Canyon Campground at Leo Carrillo",
    },
    {
      href: "/campground/south-carlsbad-state-beach-northern-end-(sites-1-34-103-130)",
      title: "South Carlsbad State Beach Camping",
    },
  ];

  let phoneHeight = 700;
  let phoneHeight2 = 650;

  return (
    <>
      <Navbar />
      <Container
        maxWidth="lg"
        sx={{
          minHeight: height - navBarHeight - footerHeight,
          overflowY: "scroll",
          padding: 0,
        }}
      >
        <Box maxWidth="lg" sx={{ marginLeft: "auto", marginRight: "auto" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              paddingTop: "30px",
              paddingLeft: { xs: "24px" },
              paddingRight: { xs: "24px" },

              marginTop: { xs: "42", sm: "64px" },
              justifyContent: "space-around",
            }}
          >
            <Stack
              direction="column"
              sx={{
                width: { xs: "100%", sm: "50%" },
                justifyContent: "center",
                paddingRight: { xs: "16px", sm: "30px" },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: "28px", sm: "45px" },
                  fontWeight: 600,
                  paddingBottom: { xs: "8px", sm: "16px" },
                  width: "100%",
                }}
              >
                Book the best campsites at sold-out campgrounds
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    xs: "16px",
                    sm: "19px",
                    lineHeight: 1.75,
                    letterSpacing: "0.00938em",
                  },
                  marginBottom: "16px",
                  fontWeight: "430",
                  // , maxWidth: "550px"
                  width: "100%",
                }}
              >
                Campwatch monitors campgrounds for availability, and notifies
                you when the perfect spot opens up
              </Typography>

              <ButtonGetStarted user={user && user} />
            </Stack>
            {!smDown && (
              <SizeMe>
                {({ size }) => (
                  <Box
                    sx={{
                      height: {
                        sm: 554.33 * 0.665625,
                      },
                      marginTop: { sm: 0 },
                      width: { sm: 554.33 },
                      borderRadius: { sm: "10px" },
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      height={426}
                      width={640}
                      placeholder="blur"
                      blurDataURL="/big-bend-national-park.jpg"
                      src="/big-bend-national-park.jpg"
                      objectFit="cover"
                      alt="Big Bend National Park"
                    />
                  </Box>
                )}
              </SizeMe>
            )}
          </Stack>
          {smDown && (
            <SizeMe>
              {({ size }) => (
                <Box
                  sx={{
                    height: {
                      xs: size.width * 0.66562,
                      sm: size.width * 0.66562,
                    },
                    marginTop: { xs: "32px", sm: 0 },
                    width: { xs: "100%", sm: 556.82 },
                    borderRadius: { xs: "0px", sm: "10px" },
                    overflow: "hidden",
                  }}
                >
                  <Image
                    height={426}
                    width={640}
                    placeholder="blur"
                    blurDataURL="/big-bend-national-park.jpg"
                    src="/big-bend-national-park.jpg"
                    objectFit="cover"
                    alt="Big Bend National Park"
                  />
                </Box>
              )}
            </SizeMe>
          )}

          <Divider variant="middle" sx={{ margin: "125px 0px" }} />
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {smDown && (
              <Box
                ref={ref5}
                sx={{ height: "175px", display: "flex", alignItems: "center" }}
              >
                <SlideAnimate
                  direction="top"
                  rootMarginBreakPoint={100}
                  inView={inView5}
                >
                  <Box sx={{ padding: "24px", marginBottom: "64px" }}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        paddingBottom: "8px",
                        lineHeight: 1.5,
                        letterSpacing: "0.00938em",
                      }}
                    >
                      Trouble booking a campsite at State and National Parks?
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        paddingBottom: "8px",
                        lineHeight: 1.5,
                        letterSpacing: "0.00938em",
                      }}
                    >
                      Tired of refreshing the campground websites only to see no
                      availabilities?{" "}
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        paddingBottom: "16px",
                        lineHeight: 1.5,
                        letterSpacing: "0.00938em",
                      }}
                    >
                      Campwatch will help you land the{" "}
                      <span
                        style={{ textDecoration: "underline", fontWeight: 700 }}
                      >
                        best
                      </span>{" "}
                      site at the Campground.
                    </Typography>
                  </Box>
                </SlideAnimate>
              </Box>
            )}
            <Box
              ref={ref4}
              sx={{
                height: phoneHeight2,
                width: phoneHeight2 * 0.51833,
                overflow: "hidden",
              }}
            >
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: "20",
                  }}
                >
                  <Image
                    // 523/1009 = .51833
                    width={phoneHeight2 * 0.51833}
                    height={phoneHeight2}
                    src="/phone-outline.svg"
                    alt="phone outline"
                  />
                </Box>
                <Box
                  sx={{
                    height: phoneHeight2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "transparent",
                  }}
                >
                  <VideoPlayerTest phoneHeight={phoneHeight2} />
                </Box>
              </Box>
            </Box>
            {!smDown && (
              <Box
                ref={ref3}
                sx={{
                  width: "375px",
                  height: "500px",
                  margin: "0px 0px 0px 100px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <SlideStaggeredChildren inView={inView3} />
              </Box>
            )}
            {smDown && (
              <>
                <Box
                  ref={ref6}
                  sx={{
                    width: "100%",
                    height: "450px",
                  }}
                >
                  <SlideStaggeredChildrenMobile
                    inView={inView6}
                    user={user && user}
                  />
                </Box>
              </>
            )}
          </Stack>
          <Divider variant="middle" sx={{ margin: "125px 0px" }} />

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", paddingBottom: "150px" }}
          >
            <Typography
              variant="h3"
              sx={{
                textAlign: "center",
                fontSize: { xs: "28px", sm: "45px" },
                fontWeight: 600,
                paddingBottom: { xs: "24px" },
                width: "100%",
              }}
            >
              How does it work?
            </Typography>
            <VideoPlayer />
            <Stack
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  marginRight: "12px",
                  marginBottom: { xs: "24px", sm: "0px" },
                  fontSize: {
                    xs: "16px",
                    sm: "19px",
                    lineHeight: 1.75,
                    letterSpacing: "0.00938em",
                  },
                  fontWeight: "600",
                  // , maxWidth: "550px"
                }}
              >
                Set up your first Campwatch alert for free!
              </Typography>
              <ButtonGetStarted user={user && user} title="Get Started" />
            </Stack>
          </Stack>

          <Box sx={{ width: "100%", padding: "24px" }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-around",
              }}
            >
              <Stack sx={{ paddingBottom: { xs: "48px", md: "32px" } }}>
                <Typography
                  variant="h3"
                  sx={{ fontSize: "18px", fontWeight: 500 }}
                >
                  🏖️Camp Lists
                </Typography>
                <Divider />
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    marginTop: "0px",
                  }}
                >
                  {linkList.map((link) => (
                    <li key={link.title} style={{ padding: "4px 0px" }}>
                      <Link
                        target="_blank"
                        href={link.href}
                        underline="hover"
                        sx={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "black",
                        }}
                      >
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Stack>
              <Stack sx={{ paddingBottom: { xs: "48px", md: "32px" } }}>
                <Typography
                  variant="h3"
                  sx={{ fontSize: "18px", fontWeight: 500 }}
                >
                  🏔️ Campgrounds
                </Typography>
                <Divider />
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    marginTop: "0px",
                  }}
                >
                  {linkList2.map((link) => (
                    <li key={link.title} style={{ padding: "4px 0px" }}>
                      <Link
                        target="_blank"
                        href={link.href}
                        underline="hover"
                        sx={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "black",
                        }}
                      >
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Stack>
            </Stack>
          </Box>
          {smDown && <FooterRowAffiliateMobile />}
        </Box>
      </Container>
      <NewFooter />
    </>
  );
}
