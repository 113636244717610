import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Stack, Typography } from "@mui/material";
import Image from "next/image";

export default function SlideStaggeredChildren({
  children,
  direction,
  inView,
  entry,
}) {
  const list = [
    {
      id: 1,
      desc: "Campwatch checks availabilities every 5 minutes, 24/7",
      src: "/svg-icons/mountains.svg",
      srcAlt: "mountain icon",
    },
    {
      id: 2,
      desc: "Weekend warrior? Get alerts for just Fri/Sat availabilities",
      src: "/svg-icons/tent-red.svg",
      srcAlt: "tent icon",
    },
    {
      id: 3,
      desc: "Want the primo campsite? Set alerts for specific sites",
      src: "/svg-icons/sleeping-bag.svg",
      srcAlt: "sleeping bag icon",
    },
    {
      id: 4,
      desc: "Need availabilities for a specific date range? No problem",
      src: "/svg-icons/trees.svg",
      srcAlt: "trees icon",
    },
    {
      id: 5,
      desc: "Big camping group? Set an alert for multiple campsites on the same night",
      src: "/svg-icons/grill.svg",
      srcAlt: "grill icon",
    },
    {
      id: 6,
      desc: "RV Camper? Search by RV length/hookups (coming soon!)",
      src: "/svg-icons/coffee.svg",
      srcAlt: "coffee icon",
    },
  ];

  const slideVariants = {
    initialStyle: { x: 15, opacity: 0 },
    animateStyle: {
      x: 0,
      opacity: 1,
      transition: {
        ease: [0.16, 1, 0.3, 1],
        when: "beforeChildren",
        staggerChildren: 0.125,
      },
    },
  };

  const childVariants = {
    initialStyle: { opacity: 0 },
    animateStyle: { opacity: 1 },
  };

  return (
    <AnimatePresence>
      {inView && (
        <motion.div
          variants={slideVariants}
          initial="initialStyle"
          animate="animateStyle"
          exit="initialStyle"
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              paddingBottom: "8px",
              lineHeight: 1.5,
              letterSpacing: "0.00938em",
            }}
          >
            Trouble booking a campsite at State and National Parks?
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              paddingBottom: "8px",
              lineHeight: 1.5,
              letterSpacing: "0.00938em",
            }}
          >
            Tired of refreshing the campground websites only to see no
            availabilities?{" "}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              paddingBottom: "16px",
              lineHeight: 1.5,
              letterSpacing: "0.00938em",
            }}
          >
            Campwatch will help you land the{" "}
            <span style={{ textDecoration: "underline", fontWeight: 700 }}>
              best
            </span>{" "}
            site at the Campground.
          </Typography>
          {list.map((item) => (
            <motion.li
              key={item.id}
              style={{ listStyle: "none" }}
              variants={childVariants}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "8px 0px",
                }}
              >
                <Image
                  // 523/1009 = .51833
                  width={30}
                  height={30}
                  src={item.src}
                  alt={item.srcAlt}
                />
                <Typography
                  variant="h4"
                  sx={{
                    paddingLeft: "12px",
                    fontSize: "16px",
                    lineHeight: 1.5,
                    letterSpacing: "0.00938em",
                  }}
                >
                  {item.desc}
                </Typography>
              </Stack>
            </motion.li>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
