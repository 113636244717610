import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import ReactPlayer from "react-player/lazy";
import { storage } from "../utils/firebase";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { SizeMe } from "react-sizeme";
import useMediaQuery from "@mui/material/useMediaQuery";
import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });
import * as gtag from "../utils/gtag.js";

// Only loads the YouTube player

const videoClicked = () => {
  gtag.event({
    action: "clicked_about_video",
    category: "click",
    label: "clicked_video",
    value: "1",
  });
};

const PlaceHolderImage = () => {
  return (
    <Box
      onClick={videoClicked}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        className="play-button"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          borderRadius: "10px",
          padding: "5px",
        }}
      >
        <PlayArrowRoundedIcon sx={{ color: "white" }} />
        <Typography sx={{ fontWeight: 600, fontSize: "24px", color: "white" }}>
          Watch Demo
        </Typography>
      </Stack>
    </Box>
  );
};

export default function VideoPlayer() {
  const smDown = useMediaQuery("(max-width:600px)");
  const [vidUrl, setVidUrl] = useState("");
  const storageRef = storage.ref();
  const videoRef = storageRef.child("campwatch-intro.mp4");
  videoRef
    .getDownloadURL()
    .then((url) => {
      setVidUrl(url);
    })
    .catch((error) => {
      console.log(error);
    });

  return (
    <SizeMe>
      {({ size }) => (
        <Box
          sx={{
            width: { xs: "100%", md: "975px" },
            height: { xs: size.width * 0.5625, md: size.width * 0.5625 },
            "&:hover": {
              opacity: 0.9,
              "& .play-button": {
                backgroundColor: "rgba(0, 0, 0, 1)",
              },
            },
          }}
        >
          <ReactPlayer
            style={{
              borderRadius: smDown ? "0px" : "10px",
              overflow: "hidden",
            }}
            light="https://firebasestorage.googleapis.com/v0/b/crud-test-4a01b.appspot.com/o/assets%2Fsquamish-valley.jpg?alt=media&token=fe2cb74f-ff38-4fae-84c3-a69ddde1bada"
            // light="./squamish-valley.jpg"
            id="myvid"
            url={vidUrl}
            width="100%"
            height="100%"
            playing={true}
            controls={true}
            volume={1}
            progressInterval={5000}
            pip={true}
            playIcon={<PlaceHolderImage />}
          />
        </Box>
      )}
    </SizeMe>
  );
}
